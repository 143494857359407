import React from 'react'
import { motion } from "framer-motion"

const About = () => {
    return (
        <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <motion.div className='flex flex-col justify-center items-center w-full h-full'
            initial={{ opacity: 0, scale:0 }}
            whileInView={{ opacity: 1, scale:1}}
            transition={{duration:0.4}}
            viewport={{ once:true}}>
                <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-[#fb0338]'>About</p>
                    </div>
                    <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-right text-4xl font-bold'>
                        <p>Hi, I'm Mihail! Nice to meet you.</p>
                    </div>
                    <div>
                        <p className='text-xl'>I am a self taught developer from Bulgaria, currently living in Vienna, Austria. I have been coding from the age of 11 and I also love working out🏋️ and drinking coffee☕.</p>

                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default About