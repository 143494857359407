import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'
import { motion } from "framer-motion"
import Me from '../assets/me.JPG'

const Home = () => {
    return (
        <div name='home' className='w-full bg-[#0a192f]'>
            {/* Container */}
            <motion.div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center w-full h-full' 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            >
                <p className='text-[#fb0338] mt-[80px]'>Hi, my name is</p>
                <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Mihail Kirkov</h1>
                <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I am a Web Developer.</h2>
                <p className='text-[#8892b0] py-4 max-w-[700px]'>I am a 19 year old programmer with a passion for Backend Development, especially with Python. Currently I am focused on improving my skills in the languages and frameworks I know, as well as learning to work with new ones.</p>
                
                <div>
                    <Link to="work" smooth={true} duration={800}>
                    
                    <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#fb0338] hover:border-[#fb0338]'>View Work
                        <span className='group-hover:rotate-90 duration-300'>
                            <HiArrowNarrowRight className='ml-3' />
                        </span>
                    </button>
                    </Link>
                    <img className='mt-4 lg:w-64 md:w-52 sm:w-44 w-40  mx-auto border-2 circle hover:animate-scale duration-200' src={Me} alt='Me'/>
                </div>
                
                
            </motion.div>
        </div>
    )
}

export default Home