import React from 'react'
import Ecom from '../assets/ecom_store.png'
import BlogImg from '../assets/blog.png'
import WeatherImg from '../assets/weather.png'
import LiveChatDjango from '../assets/django_livechat.mp4'
import ToDoDjango from '../assets/django_todo_done.mp4'
import FlaskLive from '../assets/flask_livechat_done.mp4'

import {DefaultPlayer as Video} from 'react-html5video'


const Work = () => {
    return (
        <div name='work' className='bg-[#0a192f] w-full md:h-screen text-gray-300' id="work">
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#fb0338]'>Work Experience</p>
                    <p className='text-2xl mt-3'>Coding Teacher for Coding Giants Bulgaria</p>
                    <p>August 2023 - ongoing (part-time, remote)</p>
                    <p className='text-xl mt-3'>We teach complete beginners, mostly kids, the basics of programming. In this jobs I have worked with C#, and improved my team-working and communication skills a lot.</p>
                    <p className='py-6'>// Check out some of my recent projects</p>
                    <p> (test: user=demo, password=passdemo)</p>
                </div>

                {/* Container */}
                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    {/* Grid Item */}
                    
                    <div style={{backgroundImage:`url(${BlogImg})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>Blog Posts App</p>
                            </span>

                            <div className='pt-6 text-center'>
                                <a href='https://mihail-blog.onrender.com' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                                </a>
                                <a href='https://github.com/MihailKirkov/blog-django-new' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Django, Javascript, HTML, CSS</p>
                            </div>

                        </div>
                    </div>
                    
                    <div name='ecom_web' style={{backgroundImage:`url(${Ecom})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>Ecommerce Website</p>
                            </span>

                            <div className='pt-8 text-center'>
                                <a href='https://mihail-ecom.up.railway.app' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                                </a>
                                <a href='https://github.com/MihailKirkov/ecom-django' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Django, HTML, CSS</p>
                            </div>

                        </div>
                    </div>

                    

                    <div style={{backgroundImage:`url(${WeatherImg})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>Weather App</p>
                            </span>

                            <div className='pt-8 text-center'>
                                <a href='https://weather-app-mihail.onrender.com' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                                </a>
                                <a href='https://github.com/MihailKirkov/weather_app' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Flask, HTML, CSS, Weather API</p>
                            </div>

                        </div>
                    </div>


                    <div style={{ position: 'relative'}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div content-div-vid'>
                        <video autoPlay muted loop className="video-background">
                            <source src={LiveChatDjango} type="video/mp4" />
                        </video>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>LiveChat Rooms Django</p>
                            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://github.com/MihailKirkov/live-chat-with-djangowebsockets' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Django, JavaScript, WebSockets, HTML, CSS</p>
                            </div>
                        </div>
                    </div>


                    <div style={{ position: 'relative'}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div content-div-vid'>
                        <video autoPlay muted loop className="video-background">
                            <source src={ToDoDjango} type="video/mp4" />
                        </video>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>To-Do CRUD App</p>
                            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://github.com/MihailKirkov/django-react-todo' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Django, React, HTML, CSS</p>
                            </div>
                        </div>
                    </div>


                    <div style={{ position: 'relative'}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div content-div-vid'>
                        <video autoPlay muted loop className="video-background">
                            <source src={FlaskLive} type="video/mp4" />
                        </video>
                        {/* Hover Effects */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider justify-center text-center'>
                                <p>LiveChat Rooms Flask</p>
                            </span>
                            <div className='pt-8 text-center'>
                                <a href='https://github.com/MihailKirkov/livechat-flask' target='_blank' rel="noreferrer">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                                </a>
                                <p>Techs Used:</p>
                                <p>Python, Flask, JavaScript, SocketIO, HTML, CSS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work