import React from 'react'

const Contact = () => {
    return (
        <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
            <form method='POST' action='https://getform.io/f/8380484d-00b2-4fa2-921e-e5bb01351326' className='flex flex-col max-w-[600px] w-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-[#fb0338] text-gray-300'>Contact Me</p>
                    <p className='text-gray-300 py-4'>// Submit The Form Below or send me an email - mihailkirkov04@gmail.com</p>
                </div>
                <input className='my-4 p-2 bg-[#ccd6f6]' type="text" placeholder='Name' name='name'/>
                <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='email'/>
                <textarea className='bg-[#ccd6f6] p-2' placeholder='Message' name='message' rows='10' />
                <button className='text-white border-2 hover:bg-[#fb0338] hover:border-[#fb0338] px-4 py-3 my-8 mx-auto flex items-center'>Send Email</button>
            </form>
        </div>
    )
}

export default Contact