import React from 'react'

import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JavaScript from '../assets/javascript.png'
import ReactImg from '../assets/react.png'
import SQL from '../assets/sql.png'
import Flask from '../assets/flask.png'
import Django from '../assets/django.png'
import GitHub from '../assets/github.png'
import Tailwind from '../assets/tailwind.png'
import Python from '../assets/python.png'
import Csharp from '../assets/Csharp.png'
import CPP from '../assets/c++.png'
import { motion } from "framer-motion"



const Skills = () => {
    return (
        <div name='skills' className='w-full min-h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <motion.div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'
            initial={{ scale:0.2}}
            whileInView={{ scale:1}}
            transition={{duration:0.4}}
            viewport={{ once:true}}>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-[#fb0338]'>My Skills</p>
                    <p className='py-4'> // These are the technologies I've worked with</p>
                </div>
            
                <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>

                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={Python} alt='Python icon' />
                        <p className='my-4'>Python</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={Django} alt='Django icon' />
                        <p className='my-4'>Django</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={Flask} alt='Flask icon' />
                        <p className='my-4'>Flask</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={JavaScript} alt='JavaScript icon' />
                        <p className='my-4'>JavaScript</p>                              
                    </div>
                    
                    
                    
                    
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={SQL} alt='SQL icon' />
                        <p className='my-4'>SQL DBs</p>
                    </div>
                    
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={Csharp} alt='Csharp' />
                        <p className='my-4'>C#</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={CPP} alt='CPP icon' />
                        <p className='my-4'>C++</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={GitHub} alt='GitHub icon' />
                        <p className='my-4'>GitHub</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={HTML} alt='HTML icon' />
                        <p className='my-4'>HTML</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={CSS} alt='CSS icon' />
                        <p className='my-4'>CSS</p>
                    </div>
                    
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={ReactImg} alt='ReactImg icon' />
                        <p className='my-4'>React JS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-300'>
                        <img className='w-20 mx-auto' src={Tailwind} alt='Tailwind icon' />
                        <p className='my-4'>Tailwind</p>
                    </div>
                    
                </div>
            </motion.div>
        </div>
    )
}

export default Skills