import React, {useState} from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/logo.png'
import {Link} from 'react-scroll'
import {motion} from 'framer-motion'
import PDF from '../assets/CV_MihailKirkov.docx'


const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    return (
        <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
            <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}>
                <img src={Logo} alt="Logo I" style={{width:'80px'}}/>
            </motion.div>

            {/* menu */}
            <motion.ul className='hidden md:flex' initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}>
                <li className='hover:text-[#fb0338] hover:scale-125'>
                <Link to="home" smooth={true} duration={500}>
                    Home
                </Link></li>
                <li className='hover:text-[#fb0338] hover:scale-125'><Link to="about" smooth={true} duration={500}>
                    About
                </Link></li>
                <li className='hover:text-[#fb0338] hover:scale-125'><Link to="skills" smooth={true} duration={500}>
                    Skills
                </Link></li>
                <li className='hover:text-[#fb0338] hover:scale-125'><Link to="work" smooth={true} duration={500}>
                    Work
                </Link></li>
                <li className='hover:text-[#fb0338] hover:scale-125'><Link to="contact" smooth={true} duration={500}>
                    Contact
                </Link></li>
            </motion.ul>

            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10' >
                {!nav ? <FaBars/> : <FaTimes />}
            </div>

            {/* Mobile menu */}
            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                        Home
                    </Link></li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                        About
                    </Link></li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                        Skills
                    </Link></li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                        Work
                    </Link></li>
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                        Contact
                    </Link></li>
            </ul>


            {/* Social icons */}
            <motion.div className='hidden lg:flex fixed flex-col top-[35%] left-0'initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a className='flex justify-between items-center w-full text-gray-300' href='https://www.linkedin.com/in/mihail-kirkov-b65b36262/' target='_blank' rel="noreferrer">
                            LinkedIn <FaLinkedin size={30}/>
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href='https://github.com/MihailKirkov' target='_blank' rel="noreferrer">
                            GitHub <FaGithub size={30}/>
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#fb0338]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href='mailto:mihailkirkov04@gmail.com'>
                            Email <HiOutlineMail size={30}/>
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href={PDF}>
                            Resume <BsFillPersonLinesFill size={30}/>
                        </a>
                    </li>
                </ul>
            </motion.div>
        </div>
    )
}

export default Navbar